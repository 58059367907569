<template>
  <b-card
      v-if="orders"
      no-body
      class="card-company-table"
  >
    <div style="padding: 20px 12px;" class="d-flex justify-content-between">
      <b-card-title style="font-size: 15px;font-weight: bold ">
        {{title}}
      </b-card-title>
      <div>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-info"
            to="reservationSpace"
            class="mx-1"
        >
          رزروهای اقامتگاه
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-info"
            to="reservationEvent"
        >
          رزروهای تجربه
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-info"
            to="reservationCar"
            class="mx-1"
        >
          رزروهای ترانسفر
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-info"
            to="reservationTour"
        >
          رزروهای تور
        </b-button>
      </div>
    </div>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>مهمان</b-th>
          <b-th>عنوان</b-th>
          <b-th>میزبان</b-th>
          <b-th>مجموع پرداختی</b-th>
          <b-th>تاریخ</b-th>
          <b-th>وضعیت</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item in orders" :key="item.id">
          <b-td>
            {{ item.user }}
            <br/>
            <b-badge variant="primary" v-if="item.order.guests.adult>0">
              {{ item.order.guests.adult }} بزرگسال
            </b-badge>
            <b-badge variant="primary" v-if="item.order.guests.child>0" style="margin: 2px">
              {{ item.order.guests.child }} کودک
            </b-badge>
          </b-td>
          <b-td>
            <span v-if="type==='Transactions'">
                 {{ item.order.service_name }}
                  <br/>
                  <span>تاریخ ورود یا رزرو : </span>
                  <b-badge variant="info" v-if="item.order.checkin">
                    {{ new Date(item.order.checkin).toLocaleString('fa-IR').split(",")[0]}}
                  </b-badge>
                  <br/>
                  <span v-if="item.order.checkout">
                      <span>تاریخ خروج : </span>
                      <b-badge variant="info" v-if="item.order.checkout">
                        {{ new Date(item.order.checkout).toLocaleString('fa-IR').split(",")[0]}}
                      </b-badge>
                  </span>
                  <br/>
                  <span v-if="item.order.duration>0">
                     <span>تعداد شب/مدت اجرا  : </span>
                     <b-badge variant="info" v-if="item.order.checkout">
                      {{ item.order.duration }} شب/اجرا
                     </b-badge>
                  </span>
            </span>
          </b-td>
          <b-td>
            {{item.order.service_host}}
          </b-td>
          <b-td>{{ numberFormat(item.paid) }} تومان</b-td>

          <b-td>

            {{
              new Date(item.created_at).toLocaleString("fa-IR", {
                timeZone: "Iran",
              })
            }}

          </b-td>

          <b-td>
            <b-badge variant="success"  class="badge-glow" v-if="item.status==='success'">
              موفق
            </b-badge>
            <b-badge variant="warning"  class="badge-glow" v-else>
             ناموفق
            </b-badge>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BBadge,
    BTd,
    BTbody,
    BTh,
    BTr,
    BThead,
    BTableSimple,
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props:['orders','title','type'],

  data() {
    return {

    }
  },
  methods:{
    numberFormat(number) {
      return  new Intl.NumberFormat().format(number)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
