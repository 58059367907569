<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>آمار کلی</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        خدمات روم تور
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body mb-2">
      <b-row>
        <b-col
          xl="3"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="info"
              >
                <feather-icon
                  size="24"
                  icon="HomeIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{data.total_spaces}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                  کل اقامتگاه
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            xl="3"
            sm="6"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="secondary"
              >
                <feather-icon
                    size="24"
                    icon="UmbrellaIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{data.total_experiences}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                کل تفریحات
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            xl="3"
            sm="6"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="warning"
              >
                <feather-icon
                    size="24"
                    icon="MapIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{data.total_tours}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                کل تور گردشگری
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            xl="3"
            sm="6"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="success"
              >
                <feather-icon
                    size="24"
                    icon="TruckIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{data.total_cars}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                کل ترانسفر
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
