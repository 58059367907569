<template>
  <div class="content-wrapper">
    <div class="content-body">
<!--      <section id="dashboard-ecommerce">
        <b-row>
          <b-col
              xl="4"
              md="6"
          >
            <ecommerce-medal :data="statistics.total_users" />
          </b-col>
          <b-col
              xl="8"
              md="6"
          >
            <ecommerce-statistics :data="statistics" />
          </b-col>
        </b-row>
        <b-row>
          <b-col
              xl="12"
              md="12"
          >
            <ecommerce-company-transactions-table :orders="Transactions" type="Transactions" title="آخرین تراکنش های موفق " />
          </b-col>
        </b-row>
        <b-row>
          <b-col
              xl="6"
              md="6"
          >
            <ecommerce-company-table :orders="SpaceOrders" type="Space" title="آخرین درخواست رزرو اقامتگاه" />
          </b-col>
          <b-col
              xl="6"
              md="6"
          >
            <ecommerce-company-table :orders="EventOrders" type="Event" title="آخرین درخواست رزرو تجربه" />
          </b-col>

          <b-col
              xl="6"
              md="6"
          >
            <ecommerce-company-table :orders="CarOrders" type="Car" title="آخرین درخواست رزرو ترانسفر" />
          </b-col>

          <b-col
              xl="6"
              md="6"
          >
            <ecommerce-company-table :orders="TourOrders" type="Tour" title="آخرین درخواست رزرو تور" />
          </b-col>
        </b-row>
      </section>-->
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import TableHead from "@/components/TableHead.vue";
import EcommerceMedal from "@/components/ui/EcommerceMedal.vue";
import EcommerceStatistics from "@/components/ui/EcommerceStatistics.vue";
import EcommerceCompanyTable from "@/components/ui/EcommerceCompanyTable.vue";
import EcommerceCompanyTransactionsTable from "@/components/ui/EcommerceCompanyTransactionsTable.vue";
import Pusher from "pusher-js";
import StorageService from "@/services/storage";



export default {
  components: {
    EcommerceCompanyTransactionsTable,
    EcommerceCompanyTable,
    BRow,
    BCol,
    EcommerceStatistics,
    EcommerceMedal,
    TableHead,
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      statistics: [],
      statisticsAll: [],
      SpaceOrders: [],
      EventOrders: [],
      CarOrders: [],
      TourOrders: [],
      Transactions: [],
      ADMIN_TOKEN:'FT2xYDKdeQkvT9JljUaJD9tzmB1Z5Y7RwiIDm7ROfjwcE4FGv1'
    }
  },
  mounted() {
    this.getStatistics();
    this.getAllStatistics();
    this.getSpaceOrders();
    this.getEventOrders();
    this.getCarOrders();
    this.getTourOrders();
    this.getTransactions();
    Pusher.logToConsole = true;

    var pusher = new Pusher("589de654edfe9c2b84db", {
  cluster: "eu",
});
var vm=this
var channel = pusher.subscribe(`success-transaction-admin-channel-${this.ADMIN_TOKEN}`);
channel.bind(`success-transaction-admin-event-${this.ADMIN_TOKEN}`, (data) => {
  console.log("تراکنش جدید:", data);
  if(data.message=='transaction_for_reserve_has_paid_for_admin'){
    vm.getTransactions();
    vm.$bvToast.toast('تراکنش جدید', {
            title: vm.$t("Done"),
            variant: "success",
            solid: true,
          });
  }

});

  },

  methods: {

    async getAllStatistics() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/statistics/all",

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.statistics = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getSpaceOrders() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/dashboard_all_order?orderable_type=residence",

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.SpaceOrders = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async getEventOrders() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/dashboard_all_order?orderable_type=experience",

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.EventOrders = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async getCarOrders() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/dashboard_all_order?orderable_type=car",

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.CarOrders = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getTourOrders() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/dashboard_all_order?orderable_type=tour",

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.TourOrders = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getTransactions() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/dashboard_all_transactions",

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.Transactions = data.data;
         






            
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getStatistics() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/statistics/all",

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.statisticsAll = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },




  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
