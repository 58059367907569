<template>
  <b-card
    v-if="orders"
    no-body
    class="card-company-table"
  >
      <div style="padding: 20px 12px;" class="d-flex justify-content-between">
        <b-card-title style="font-size: 15px;font-weight: bold ">
          {{title}}
        </b-card-title>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            pill
            :to="`order${type}`"
        >
          مشاهده همه
        </b-button>
      </div>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>مهمان</b-th>
          <b-th>عنوان</b-th>
          <b-th>مجموع پرداختی</b-th>
          <b-th>تاریخ</b-th>
          <b-th>وضعیت</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item in orders" :key="item.id">
          <b-td>
            {{ item.user_name }}
            <br/>
            <b-badge variant="primary" v-if="item.guests.adult>0">
              {{ item.guests.adult }} بزرگسال
            </b-badge>
            <b-badge variant="primary" v-if="item.guests.child>0" style="margin: 2px">
              {{ item.guests.child }} کودک
            </b-badge>
          </b-td>
          <b-td>
            <span v-if="type==='Space'">
                 {{ item.service_name }}
                  <br/>
                  <span>تاریخ ورود : </span>
                  <b-badge variant="info" v-if="item.checkin">
                    {{ new Date(item.checkin).toLocaleString('fa-IR').split(",")[0]}}
                  </b-badge>
                  <br/>
                  <span>تاریخ خروج : </span>
                  <b-badge variant="info" v-if="item.checkout">
                    {{ new Date(item.checkout).toLocaleString('fa-IR').split(",")[0]}}
                  </b-badge>
                  <br/>
                  <span v-if="item.duration>0">
                     <span>تعداد شب : </span>
                  <b-badge variant="info" v-if="item.checkout">
                    {{ item.duration }} شب
                  </b-badge>
                  </span>
            </span>
            <span v-if="type==='Event'">
                 {{ item.service_name }}
                  <br/>
                  <span>تاریخ رزرو : </span>
                  <b-badge variant="info" v-if="item.checkin">
                    {{ new Date(item.checkin).toLocaleString('fa-IR').split(",")[0]}}
                  </b-badge>
            </span>
            <span v-if="type==='Car'">
                 {{ item.service_name }}
                  <br/>
                  <span>تاریخ رزرو : </span>
                  <b-badge variant="info" v-if="item.checkin">
                    {{ new Date(item.checkin).toLocaleString('fa-IR').split(",")[0]}}
                  </b-badge>
            </span>
            <span v-if="type==='Tour'">
                 {{ item.service_name }}
                  <br/>
                  <span>تاریخ رزرو : </span>
                  <b-badge variant="info" v-if="item.created_at">
                    {{ new Date(item.created_at).toLocaleString('fa-IR').split(",")[0]}}
                  </b-badge>
            </span>
          </b-td>
          <b-td>{{ numberFormat(item.prices.final_price) }} تومان</b-td>
          <b-td>

            {{
              new Date(item.created_at).toLocaleString("fa-IR", {
                timeZone: "Iran",
              })
            }}

          </b-td>

          <b-td>
            <b-badge variant="dark"  class="badge-glow" v-if="item.status==='ready_to_pay'">
              میزبان تایید کرد
            </b-badge>
            <b-badge variant="warning"  class="badge-glow" v-if="item.status==='pending'">
              در انتظار میزبان
            </b-badge>
            <b-badge variant="success"  class="badge-glow" v-if="item.status==='paid'">
              پرداخته
            </b-badge>
            <b-badge variant="danger"  class="badge-glow" v-if="item.status==='canceled'">
              کنسل
            </b-badge>
            <b-badge variant="danger"  class="badge-glow" v-if="item.status==='rejected'">
              رد داده
            </b-badge>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BBadge,
    BTd,
    BTbody,
    BTh,
    BTr,
    BThead,
    BTableSimple,
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props:['orders','title','type'],

  data() {
    return {

    }
  },
  methods:{
    numberFormat(number) {
      return  new Intl.NumberFormat().format(number)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
